import {
	Box,
	Card,
	Divider,
	Drawer,
	IconButton,
	Slider,
	Typography,
} from '@mui/joy';
import React, { FC, useState } from 'react';
import SearchBox from '../SearchBox';
import MapSelection from '../MapSelection';
import { MdClear, MdMenu } from 'react-icons/md';
import { useScreenSize } from '@hooks/useScreenSize';
import CategoricalKey from './components/CategoricalKey';
import DotKey from './components/DotKey';
import MonochromeKey from './components/MonochromeKey';
import { EnergyMapType, MapState } from '@scenes/EnergyMap/useMap';

export interface LegendProps {
	state: MapState;
}

const Legend: FC<LegendProps> = ({ state }) => {
	const { size } = useScreenSize();

	const [phrase, setPhrase] = useState('');

	if (size === 'phone' || size === 'tablet')
		return <DrawerLegend state={state} phrase={phrase} setPhrase={setPhrase} />;

	return (
		<Card
			sx={{
				position: 'absolute',
				top: 24,
				left: 24,
				bottom: 24,
				display: 'flex',
				flexFlow: 'column',
				gap: 2,
				maxWidth: 300,
			}}
		>
			<Typography level="h2">US Energy Map</Typography>
			<SearchBox
				variant="soft"
				value={phrase}
				onChange={setPhrase}
				onSearch={state.goTo.handler}
				loading={state.goTo.loading}
			/>
			<MapSelection
				variant="soft"
				value={state.mode.value}
				onChange={state.mode.setValue}
			/>
			<Divider />
			<Box
				sx={{
					flex: 1,
					overflow: 'auto',
					'&::-webkit-scrollbar': {
						display: 'none',
					},
				}}
			>
				{state.mode.value === EnergyMapType.Categorical && (
					<CategoricalKey state={state} />
				)}
				{state.mode.value === EnergyMapType.Renewability && (
					<DotKey state={state} />
				)}
				{state.mode.value === EnergyMapType.Monochrome && (
					<MonochromeKey state={state} />
				)}
			</Box>
		</Card>
	);
};

export default Legend;

const DrawerLegend: FC<
	LegendProps & { phrase: string; setPhrase: (value: string) => void }
> = ({ state, phrase, setPhrase }) => {
	const [open, setOpen] = React.useState(false);

	const handleSearch = (place: string) => {
		setOpen(false);
		state.goTo.handler(place);
	};

	const minPitch = 0;
	const maxPitch = 90;
	const relative =
		((state.view.value.pitch - minPitch) / (maxPitch - minPitch)) * 100;

	return (
		<>
			<Box
				sx={{
					position: 'absolute',
					top: 24,
					right: 24,
					zIndex: 10,
				}}
			>
				<IconButton
					color="neutral"
					variant="solid"
					size="lg"
					onClick={() => setOpen(true)}
				>
					<MdMenu />
				</IconButton>
			</Box>
			<Box
				sx={{
					position: 'absolute',
					bottom: 48,
					right: 24,
					zIndex: 10,
				}}
			>
				<Box sx={{ height: '160px' }}>
					<Slider
						color="neutral"
						size="lg"
						orientation="vertical"
						value={relative}
						onChange={(e, val) => {
							if (typeof val === 'number') {
								const pitch = ((maxPitch - minPitch) * val) / 100 + minPitch;
								state.view.setValue({ ...state.view.value, pitch });
							}
						}}
					/>
				</Box>
			</Box>
			<Drawer
				size="lg"
				anchor="bottom"
				open={open}
				onClose={() => setOpen(false)}
				slotProps={{
					content: {
						sx: {
							height: '80vh',
						},
					},
				}}
			>
				<Box
					sx={{
						p: 3,
						display: 'flex',
						flexFlow: 'column',
						gap: 3,
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Typography level="h2">US Energy Map</Typography>
						<IconButton
							onClick={() => {
								setOpen(false);
							}}
							size="lg"
						>
							<MdClear />
						</IconButton>
					</Box>
					<SearchBox
						variant="soft"
						size="lg"
						value={phrase}
						onChange={setPhrase}
						onSearch={handleSearch}
						loading={state.goTo.loading}
					/>
					<MapSelection
						variant="soft"
						size="lg"
						value={state.mode.value}
						onChange={state.mode.setValue}
					/>
					<Divider />
					{state.mode.value === EnergyMapType.Categorical && (
						<CategoricalKey state={state} />
					)}
					{state.mode.value === EnergyMapType.Renewability && (
						<DotKey state={state} />
					)}
					{state.mode.value === EnergyMapType.Monochrome && (
						<MonochromeKey state={state} />
					)}
				</Box>
			</Drawer>
		</>
	);
};
