import {
	getMutationHook,
	getQueryHook,
	getSubscriptionHook,
} from '../../hooks/operations';
import { BoardState } from './BoardState';
import { BoardStateInput } from './BoardStateInput';
import {
	BoardStateQuery,
	BoardStateSubscription,
	SetBoardStateMutation,
	SetPageMutation,
} from './_operations';

const boardStateSchema = {
	get: getQueryHook<{ BoardState: BoardState }>(BoardStateQuery),
	subscribe: getSubscriptionHook<{ BoardStateSubscription: BoardState }>(
		BoardStateSubscription
	),
	setPage: getMutationHook<{ SetPage: boolean }, { page: number }>(
		SetPageMutation
	),
	setState: getMutationHook<
		{ SetBoardState: BoardState },
		{ state: BoardStateInput }
	>(SetBoardStateMutation),
};

export default boardStateSchema;
