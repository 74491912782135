import { gql } from '@apollo/client';
import { BoardStateFragment } from './BoardState';
import { LocalFragment } from '@schema/Local/Local';
import { RegionalFragment } from '@schema/Regional/Regional';
import { NationalFragment } from '@schema/National/National';
import { ViewStateFragment } from '@schema/ViewState/ViewState';

export const BoardStateSubscription = gql`
	${BoardStateFragment}
	${LocalFragment}
	${RegionalFragment}
	${NationalFragment}
	${ViewStateFragment}
	subscription BoardStateSubscription {
		BoardStateSubscription {
			...BoardStateFragment
		}
	}
`;

export const BoardStateQuery = gql`
	${BoardStateFragment}
	${LocalFragment}
	${RegionalFragment}
	${NationalFragment}
	${ViewStateFragment}
	query BoardStateQuery {
		BoardState {
			...BoardStateFragment
		}
	}
`;

export const SetBoardStateMutation = gql`
	${BoardStateFragment}
	${LocalFragment}
	${RegionalFragment}
	${NationalFragment}
	${ViewStateFragment}
	mutation SetBoardStateMutation($state: BoardStateInput!) {
		SetBoardState(state: $state) {
			...BoardStateFragment
		}
	}
`;

export const SetPageMutation = gql`
	mutation SetPageMutation($page: Int!) {
		SetPage(page: $page)
	}
`;
