import boardStateSchema from './BoardState/_schema';
import descriptionSchema from './Description/_schema';
import placeSchema from './Place/_schema';

const schema = {
	boardState: boardStateSchema,
	description: descriptionSchema,
	place: placeSchema,
};

export default schema;
