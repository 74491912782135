import { FormControl, FormLabel, Option, Select, SelectProps } from '@mui/joy';
import { EnergyMapType } from '@scenes/EnergyMap/useMap';
import React, { FC } from 'react';

export interface MapSelectionProps
	extends Omit<SelectProps<any, false>, 'value' | 'onChange'> {
	value: EnergyMapType;
	onChange: (value: EnergyMapType) => void;
}

const MapSelection: FC<MapSelectionProps> = ({ value, onChange, ...rest }) => {
	return (
		<FormControl>
			<FormLabel>Map Type</FormLabel>
			<Select
				{...rest}
				placeholder="Map Type"
				value={value}
				onChange={(e, t) => {
					if (t) onChange(t);
				}}
			>
				{Object.keys(EnergyMapType).map((value) => (
					<Option key={value} value={value}>
						{value}
					</Option>
				))}
			</Select>
		</FormControl>
	);
};

export default MapSelection;
