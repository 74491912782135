import { gql } from '@apollo/client';
import { DescriptionFragment } from './Description';

export const DescriptionQuery = gql`
	${DescriptionFragment}
	query DescriptionQuery($content: String!) {
		Description(content: $content) {
			...DescriptionFragment
		}
	}
`;
