import { getLazyQueryHook, getQueryHook } from '@hooks/operations';
import { DescriptionQuery } from './_operations';
import { Description } from './Description';

const descriptionSchema = {
	get: getQueryHook<{ Description: Description }, { content: string }>(
		DescriptionQuery
	),
	lazy: getLazyQueryHook<{ Description: Description }, { content: string }>(
		DescriptionQuery
	),
};

export default descriptionSchema;
