import mapboxgl from 'mapbox-gl';

export const getFeaturePrompt = (feature: mapboxgl.MapboxGeoJSONFeature) => {
	const res = `Provide a succinct description of the ${feature.properties?.Plant_Name} power plant.
  The plant is located in ${feature.properties?.City}, ${feature.properties?.County}.
  The plant is a ${feature.properties?.tech_desc} with a capacity of ${feature.properties?.Total_MW} MW.

  Talk about the inherint competing values in the plants production methods.
  Do not be overly positive or negative, but rather provide a balanced view of the plant.
  
  `;

	return res;
};
