import { gql } from '@apollo/client';
import { ViewState } from '@schema/ViewState/ViewState';

export class Place extends ViewState {}

export const PlaceFragment = gql`
	fragment PlaceFragment on Place {
		longitude
		latitude
		zoom
		pitch
		bearing
	}
`;
