import { gql } from '@apollo/client';
import { ViewState } from '@schema/ViewState/ViewState';

export class National {
	viewState!: ViewState;
}

export const NationalFragment = gql`
	fragment NationalFragment on National {
		viewState {
			...ViewStateFragment
		}
	}
`;
