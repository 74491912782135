import { gql } from '@apollo/client';
import { PlaceFragment } from './Place';

export const PlaceQuery = gql`
	${PlaceFragment}
	query PlaceQuery($name: String!) {
		Place(name: $name) {
			...PlaceFragment
		}
	}
`;
