import { useEffect, useState } from 'react';
import { MapRef, ViewState } from 'react-map-gl';
import schema from '@schema/index';
import { useParams } from 'react-router-dom';

export enum College {
	ID = 'ID',
	RISD = 'RISD',
	Berkeley = 'Berkeley',
	Harvard = 'Harvard',
	Penn = 'Penn',
}

export const collegeLocations: Record<College, ViewState> = {
	ID: {
		latitude: 41.8349,
		longitude: -87.627,
		zoom: 9,
		pitch: 45,
		bearing: -20,
		padding: {
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		},
	},
	RISD: {
		latitude: 41.8262,
		longitude: -71.4049,
		zoom: 9,
		pitch: 45,
		bearing: -20,
		padding: {
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		},
	},
	Berkeley: {
		latitude: 37.8719,
		longitude: -122.2585,
		zoom: 9,
		pitch: 45,
		bearing: -20,
		padding: {
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		},
	},
	Harvard: {
		latitude: 42.377,
		longitude: -71.1167,
		zoom: 9,
		pitch: 45,
		bearing: -20,
		padding: {
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		},
	},
	Penn: {
		latitude: 39.9522,
		longitude: -75.1932,
		zoom: 9,
		pitch: 45,
		bearing: -20,
		padding: {
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		},
	},
};

export enum Technology {
	Solar = 'Solar',
	NaturalGas = 'NaturalGas',
	Nuclear = 'Nuclear',
	Geothermal = 'Geothermal',
	Hydroelectric = 'Hydroelectric',
	Wind = 'Wind',
	Coal = 'Coal',
	Biomass = 'Biomass',
	Petroleum = 'Petroleum',
}

export enum TechnologyClass {
	Renewable = 'Renewable',
	NonRenewable = 'NonRenewable',
	Nuclear = 'Nuclear',
}

export const technologyClasses: Record<Technology, TechnologyClass> = {
	Solar: TechnologyClass.Renewable,
	NaturalGas: TechnologyClass.NonRenewable,
	Nuclear: TechnologyClass.Nuclear,
	Geothermal: TechnologyClass.Renewable,
	Hydroelectric: TechnologyClass.Renewable,
	Wind: TechnologyClass.Renewable,
	Coal: TechnologyClass.NonRenewable,
	Biomass: TechnologyClass.Renewable,
	Petroleum: TechnologyClass.NonRenewable,
};

const technologyNames: Record<Technology, string> = {
	Solar: 'solar',
	NaturalGas: 'natural gas',
	Nuclear: 'nuclear',
	Geothermal: 'geothermal',
	Hydroelectric: 'hydroelectric',
	Wind: 'wind',
	Coal: 'coal',
	Biomass: 'biomass',
	Petroleum: 'petroleum',
};

export const techColors: Record<Technology, string> = {
	Solar: '#f1e427',
	NaturalGas: '#69e281',
	Nuclear: '#7d33e6',
	Wind: '#42cee0',
	Hydroelectric: '#333ceb',
	Coal: '#e91616',
	Biomass: '#9e978f',
	Petroleum: '#d56feb',
	Geothermal: '#ea883e',
};

export const techClassColors: Record<TechnologyClass, string> = {
	Renewable: '#31924F',
	NonRenewable: '#2D5FCD',
	Nuclear: '#8943CC',
};

export enum EnergyMapType {
	Renewability = 'Renewability',
	Monochrome = 'Monochrome',
	Categorical = 'Categorical',
}

const forceRefresh = true;
const refreshString = forceRefresh ? `?fresh=${new Date().getTime()}` : '';

export const connections: Record<EnergyMapType, string> = {
	Renewability:
		'mapbox://styles/morganddoane/clp37bcih00et01rb4x62bkaz' ,
	Monochrome:
		'mapbox://styles/morganddoane/clp26evch00d101pq4jqw0fhy' ,
	Categorical:
		'mapbox://styles/morganddoane/clp24ixii00eb01rbauol1d1d' ,
};

const defaultState: ViewState = {
	latitude: 40.7608,
	longitude: -111.891,
	zoom: 10,
	pitch: 45,
	bearing: -20,
	padding: {
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
};

export interface MapState {
	mode: {
		value: EnergyMapType;
		setValue: (mode: EnergyMapType) => void;
	};
	view: {
		value: ViewState;
		setValue: (view: ViewState) => void;
	};
	goTo: {
		handler: (name: string) => void;
		loading: boolean;
	};
	technology: {
		value: Technology[];
		setValue: (technologies: Technology[]) => void;
	};
	technologyClass: {
		value: TechnologyClass[];
		setValue: (technologyClasses: TechnologyClass[]) => void;
	};
	connection: string;
}

export const useMapState = (ref: React.RefObject<MapRef>): MapState => {
	const { location: defaultLocation } = useParams();
	const stateFromStorage = localStorage.getItem('energy-map-state');
	const modeFromStorage = localStorage.getItem('energy-map-mode');

	const [hasDefault, setHasDefault] = useState<boolean>(false);

	const [focusedTechnology, setFocusedTechnology] = useState<Technology[]>([]);
	const [focusedTechnologyClass, setFocusedTechnologyClass] = useState<
		TechnologyClass[]
	>([]);

	const [mode, setMode] = useState<EnergyMapType>(
		modeFromStorage
			? (modeFromStorage as EnergyMapType)
			: EnergyMapType.Categorical
	);

	const [view, setView] = useState<ViewState>(
		stateFromStorage ? JSON.parse(stateFromStorage) : defaultState
	);

	useEffect(() => {
		localStorage.setItem('energy-map-state', JSON.stringify(view));
	}, [view]);

	useEffect(() => {
		setFocusedTechnology([]);
		setFocusedTechnologyClass([]);
		localStorage.setItem('energy-map-mode', mode);
	}, [mode]);

	useEffect(() => {
		const map = ref.current?.getMap();
		const grey = '#57524C';
		if (map && mode === EnergyMapType.Categorical) {
			const glow = map.getLayer('PlantGlow');
			const extrusion = map.getLayer('PlantExtrusions');
			const labels = map.getLayer('PlantLabels');

			if (glow && extrusion && labels) {
				const getColor = (tech: Technology) => {
					if (
						focusedTechnology.length === 0 ||
						focusedTechnology.includes(tech)
					) {
						return techColors[tech];
					}
					return grey;
				};

				const getOpacity = (tech: Technology) => {
					if (
						focusedTechnology.length === 0 ||
						focusedTechnology.includes(tech)
					) {
						return 1;
					}
					return 0.2;
				};

				const colorExpression: mapboxgl.Expression = [
					'match',
					['get', 'PrimSource'],
					...Object.entries(technologyNames)
						.flatMap(([key, value]) => [value, getColor(key as Technology)])
						.flat(),
					grey, // Default color
				];

				const opacityExpression: mapboxgl.Expression = [
					'match',
					['get', 'PrimSource'],
					...Object.entries(technologyNames)
						.flatMap(([key, value]) => [value, getOpacity(key as Technology)])
						.flat(),
					1,
				];

				map.setPaintProperty(glow.id, 'circle-color', colorExpression);
				map.setPaintProperty(glow.id, 'circle-opacity', opacityExpression);
				map.setPaintProperty(
					extrusion.id,
					'fill-extrusion-color',
					colorExpression
				);
				map.setPaintProperty(labels.id, 'text-color', colorExpression);
			}
		} else if (map && mode === EnergyMapType.Renewability) {
			const plants = map.getLayer('Plants');

			if (plants) {
				const getColor = (tech: Technology) => {
					const techClass = technologyClasses[tech];
					if (
						focusedTechnologyClass.length === 0 ||
						focusedTechnologyClass.includes(techClass)
					) {
						return techClassColors[techClass];
					}
					return grey;
				};

				const colorExpression: mapboxgl.Expression = [
					'match',
					['get', 'PrimSource'],
					...Object.entries(technologyNames)
						.flatMap(([key, value]) => [value, getColor(key as Technology)])
						.flat(),
					grey, // Default color
				];

				map.setPaintProperty(plants.id, 'circle-color', colorExpression);
			}
		}
	}, [mode, ref, focusedTechnology, focusedTechnologyClass]);

	const [search, { loading: searchLoading }] = schema.place.lazy({
		onCompleted: ({ Place }) => {
			ref.current?.flyTo({
				center: [Place.longitude, Place.latitude],
				zoom: Place.zoom,
				duration: 5000,
				pitch: Place.pitch,
				bearing: Place.bearing,
			});
		},
	});

	const onSearch = (name: string) => {
		search({
			variables: {
				name,
			},
		});
	};

	useEffect(() => {
		if (defaultLocation && !hasDefault) {
			const match = Object.entries(collegeLocations).find(
				([key]) => key.toLowerCase() === defaultLocation.toLowerCase()
			);

			if (match) {
				setView(match[1]);
				setHasDefault(true);
			}
		}
	}, [defaultLocation, hasDefault]);

	return {
		mode: {
			value: mode,
			setValue: (mode: EnergyMapType) => {
				setMode(mode);
			},
		},
		view: {
			value: view,
			setValue: (view: ViewState) => {
				setView(view);
			},
		},
		goTo: {
			handler: onSearch,
			loading: searchLoading,
		},
		technology: {
			value: focusedTechnology,
			setValue: (technologies: Technology[]) => {
				setFocusedTechnology(technologies);
			},
		},
		technologyClass: {
			value: focusedTechnologyClass,
			setValue: (technologyClasses: TechnologyClass[]) => {
				setFocusedTechnologyClass(technologyClasses);
			},
		},
		connection: connections[mode],
	};
};
