import { Box, useColorScheme } from '@mui/joy';
import React, { FC, PropsWithChildren, useEffect } from 'react';

export interface ScreenProps {}

const Screen: FC<PropsWithChildren<ScreenProps>> = ({ children }) => {
	const { mode, setMode } = useColorScheme();

	useEffect(() => {
		if (mode !== 'dark') {
			setMode('dark');
		}
	}, [mode, setMode]);

	return (
		<Box
			sx={{
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				background: 'black',
				overflow: 'hidden',
			}}
		>
			{children}
		</Box>
	);
};

export default Screen;
