import { Box, Button, Typography } from '@mui/joy';
import React, { FC } from 'react';
import { MdArrowForward, MdCheck } from 'react-icons/md';

export interface ColorKeyProps<Key extends string | number | symbol> {
	children: Record<Key, string>;
	onClick?: (color: Key) => void;
	isActive?: (color: Key) => boolean;
}

const ColorKey = <Key extends string | number | symbol>({
	children,
	onClick,
	isActive = (color: Key) => false,
}: ColorKeyProps<Key>) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexFlow: 'column',
				gap: 1,
			}}
		>
			{Object.entries(children).map(([label, color]) => {
				const active = isActive(label as Key);
				return (
					<Button
						variant={active && onClick ? 'soft' : 'plain'}
						color="neutral"
						disabled={!onClick}
						size="sm"
						key={label}
						sx={{
							display: 'flex',
							gap: 1,
							alignItems: 'center',
							cursor: onClick ? 'pointer' : 'default',
							justifyContent: 'flex-start',
						}}
						onClick={() => {
							if (onClick) onClick(label as Key);
						}}
						endDecorator={active && <MdArrowForward />}
					>
						<Box
							sx={{
								width: 12,
								height: 12,
								borderRadius: '50%',
								backgroundColor: color as string,
							}}
						/>
						<Box
							sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}
						>
							<Typography>{label}</Typography>
						</Box>
					</Button>
				);
			})}
		</Box>
	);
};

export default ColorKey;
