import { getLazyQueryHook, getQueryHook } from '@hooks/operations';
import { Place } from './Place';
import { PlaceQuery } from './_operations';

const placeSchema = {
	get: getQueryHook<{ Place: Place }, { name: string }>(PlaceQuery),
	lazy: getLazyQueryHook<{ Place: Place }, { name: string }>(PlaceQuery),
};

export default placeSchema;
