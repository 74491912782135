import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ThemeProvider from '@providers/ThemeProvider';
import DataProvider from '@providers/DataProvider';
import EnergyMap from '@scenes/EnergyMap';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<ThemeProvider>
				<DataProvider>
					<Routes>
						<Route index element={<EnergyMap />} />
						<Route path=":location" element={<EnergyMap />} />
					</Routes>
				</DataProvider>
			</ThemeProvider>
		</BrowserRouter>
	</React.StrictMode>
);
