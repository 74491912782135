import { Box, FormControl, FormLabel, Typography } from '@mui/joy';
import React, { FC } from 'react';
import ColorKey from '../ColorKey';
import { MapState } from '@scenes/EnergyMap/useMap';

export interface MonochromeKeyProps {
	state: MapState;
}

const MonochromeKey: FC<MonochromeKeyProps> = ({ state }) => {
	return (
		<FormControl>
			<FormLabel>Key</FormLabel>
			<Typography>
				This map represents the total production capacity of power plants
				accross the United States. The vertical extrusions represent the
				capacity of each plant, while the vasculature represents the movement of
				electricity from the plants into the grid.
			</Typography>
			<Box p={1} />
			<Typography>
				The energy that feeds your home is generated by an amalgamation of power
				plants across the country, making it difficult to near impossible to
				know exactly where your electricity comes from.
			</Typography>
			<Box p={2} />
			<ColorKey>
				{{
					'All Energy Types': '#C3BE33',
				}}
			</ColorKey>
		</FormControl>
	);
};

export default MonochromeKey;
