import { gql } from '@apollo/client';
import { Local } from '@schema/Local/Local';
import { National } from '@schema/National/National';
import { Regional } from '@schema/Regional/Regional';

export class BoardState {
	page!: number;
	local!: Local;
	regional!: Regional;
	national!: National;
}

export const BoardStateFragment = gql`
	fragment BoardStateFragment on BoardState {
		page
		local {
			...LocalFragment
		}
		regional {
			...RegionalFragment
		}
		national {
			...NationalFragment
		}
	}
`;
