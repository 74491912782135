import { useScreenSize } from '@hooks/useScreenSize';
import { Box, CircularProgress, Drawer, Typography } from '@mui/joy';
import mapboxgl from 'mapbox-gl';
import React, { FC } from 'react';

export interface DetailDrawerProps {
	onClose: () => void;
	focused: mapboxgl.MapboxGeoJSONFeature | null;
	description: string;
	loading: boolean;
}

const DetailDrawer: FC<DetailDrawerProps> = ({
	focused,
	description,
	loading,
	onClose,
}) => {
	const { size } = useScreenSize();
	const small = size === 'phone';

	// tech_desc
	// Plant_Name
	return (
		<Drawer
			onClose={onClose}
			slotProps={{
				content: small
					? {
							sx: {
								height: '80vh',
							},
					  }
					: undefined,
			}}
			anchor={small ? 'bottom' : 'right'}
			open={!!focused}
		>
			<Box
				sx={{
					height: '100%',
					overflow: 'auto',
					p: 3,
				}}
			>
				<Typography level="h2">{focused?.properties?.Plant_Name}</Typography>
				<Typography level="body-lg">
					{focused?.properties?.tech_desc}
				</Typography>
				<Box p={1} />
				{loading ? (
					<CircularProgress />
				) : (
					<Typography
						sx={{
							whiteSpace: 'pre-wrap',
						}}
					>
						{description}
					</Typography>
				)}
			</Box>
		</Drawer>
	);
};

export default DetailDrawer;
