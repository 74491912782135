import { gql } from '@apollo/client';

export class ViewState {
	longitude!: number;
	latitude!: number;
	zoom!: number;
	pitch!: number;
	bearing!: number;
}

export const ViewStateFragment = gql`
	fragment ViewStateFragment on ViewState {
		longitude
		latitude
		zoom
		pitch
		bearing
	}
`;
