import { Box, FormControl, FormLabel, Typography } from '@mui/joy';
import React, { FC } from 'react';
import ColorKey from '../ColorKey';
import { MapState, techColors } from '@scenes/EnergyMap/useMap';

export interface CategoricalKeyProps {
	state: MapState;
}

const CategoricalKey: FC<CategoricalKeyProps> = ({ state }) => {
	return (
		<FormControl>
			<FormLabel>Key</FormLabel>
			<Typography>
				This map represents the total production capacity & categories of power
				plants accross the United States. The vertical extrusions represent the
				capacity of each plant, their color indicating their type. The
				vasculature represents the movement of electricity from the plants into
				the grid.
			</Typography>
			<Box p={1} />
			<Typography>
				The energy grid is the world's largest machine. However, it is not
				controlled by a single entity, but rather a patchwork of over 3,000
				private and public companies.
			</Typography>
			<Box p={2} />
			<ColorKey
				onClick={(tech) => {
					if (state.technology.value.includes(tech)) {
						state.technology.setValue(
							state.technology.value.filter((t) => t !== tech)
						);
					} else {
						state.technology.setValue([...state.technology.value, tech]);
					}
				}}
				isActive={(tech) => state.technology.value.includes(tech)}
			>
				{techColors}
			</ColorKey>
		</FormControl>
	);
};

export default CategoricalKey;
