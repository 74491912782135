import { gql } from '@apollo/client';

export class Description {
	content!: string;
}

export const DescriptionFragment = gql`
	fragment DescriptionFragment on Description {
		content
	}
`;
