import {
	SubscriptionHookOptions,
	LazyQueryHookOptions,
	MutationHookOptions,
	OperationVariables,
	QueryHookOptions,
	useLazyQuery,
	useMutation,
	useSubscription,
	useQuery,
} from '@apollo/client';
import { DocumentNode } from 'graphql';

export const getQueryHook =
	<Result, Args extends OperationVariables = {}>(query: DocumentNode) =>
	(options?: QueryHookOptions<Result, Args>) =>
		useQuery<Result, Args>(query, options);

export const getLazyQueryHook =
	<Result, Args extends OperationVariables = {}>(query: DocumentNode) =>
	(options?: LazyQueryHookOptions<Result, Args>) =>
		useLazyQuery<Result, Args>(query, options);

export const getMutationHook =
	<Result, Args extends OperationVariables = {}>(mutation: DocumentNode) =>
	(options?: MutationHookOptions<Result, Args>) =>
		useMutation<Result, Args>(mutation, options);

export const getSubscriptionHook =
	<Result, Args extends OperationVariables = {}>(subscription: DocumentNode) =>
	(options?: SubscriptionHookOptions<Result, Args>) =>
		useSubscription<Result, Args>(subscription, options);
