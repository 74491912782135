import { useEffect, useState } from 'react';

export type ScreenSize = 'phone' | 'tablet' | 'desktop';

export interface ScreenSizeProps {
	size: ScreenSize;
	sizeIs: (size: ScreenSize) => boolean;
}

export const useScreenSize = (
	onChange?: (size: ScreenSize) => void
): ScreenSizeProps => {
	const fromStorage = localStorage.getItem('screenSize');

	const [size, setSize] = useState<ScreenSize>(
		(fromStorage as ScreenSize) || 'desktop'
	);

	useEffect(() => {
		localStorage.setItem('screenSize', size);
	}, [size]);

	const sizeIs = (test: ScreenSize) => test === size;

	useEffect(() => {
		const onResize = () => {
			const width = window.innerWidth;

			if (width < 768) {
				setSize('phone');
			} else if (width < 1024) {
				setSize('tablet');
			} else {
				setSize('desktop');
			}
		};

		onResize();

		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	useEffect(() => {
		onChange?.(size);
	}, [size]);

	return { size, sizeIs };
};
