import { Box, FormControl, FormLabel, Typography } from '@mui/joy';
import React, { FC } from 'react';
import ColorKey from '../ColorKey';
import { MapState, techClassColors } from '@scenes/EnergyMap/useMap';

export interface DotKeyProps {
	state: MapState;
}

const DotKey: FC<DotKeyProps> = ({ state }) => {
	return (
		<FormControl>
			<FormLabel>Key</FormLabel>
			<Typography>
				This map represents the total production capacity & renewable status of
				power plants accross the United States. The dot radii represent the the
				capacity of each power plant, while the vasculature represents the
				movement of electricity from the plants into the grid.
			</Typography>
			<Box p={1} />
			<Typography>
				The energy that feeds your home is generated by an amalgamation of power
				plants across the country, making it difficult to near impossible to
				know exactly where your electricity comes from.
			</Typography>
			<Box p={2} />
			<ColorKey
				onClick={(techClass) => {
					if (state.technologyClass.value.includes(techClass)) {
						state.technologyClass.setValue(
							state.technologyClass.value.filter((t) => t !== techClass)
						);
					} else {
						state.technologyClass.setValue([
							...state.technologyClass.value,
							techClass,
						]);
					}
				}}
				isActive={(techClass) =>
					state.technologyClass.value.includes(techClass)
				}
			>
				{techClassColors}
			</ColorKey>
		</FormControl>
	);
};

export default DotKey;
