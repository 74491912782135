import { CssVarsProvider, extendTheme } from '@mui/joy';
import React, { PropsWithChildren } from 'react';

const theme = extendTheme({
	colorSchemes: {
		light: {
			palette: {
				background: {
					body: '#EFEDE8',
				},
			},
		},
	},
	fontFamily: {
		display: 'Greycliff',
		body: 'NovaletraSerif',
	},
});

const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
	return <CssVarsProvider theme={theme}>{children}</CssVarsProvider>;
};

export default ThemeProvider;
