import { gql } from '@apollo/client';
import { ViewState } from '@schema/ViewState/ViewState';

export class Local {
	viewState!: ViewState;
}

export const LocalFragment = gql`
	fragment LocalFragment on Local {
		viewState {
			...ViewStateFragment
		}
	}
`;
