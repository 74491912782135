import Screen from '@components/layout/Screen';
import React, { FC, useRef, useState } from 'react';
import Map, { MapRef } from 'react-map-gl';
import Legend from './components/Legend';
import { useMapState } from './useMap';
import 'mapbox-gl/dist/mapbox-gl.css';
import './map.css';
import mapboxgl from 'mapbox-gl';
import schema from '@schema/index';
import { getFeaturePrompt } from './getFeaturePrompt';
import DetailDrawer from './components/DetailDrawer';

export interface EnergyMapProps {}

const token = process.env.REACT_APP_MAPBOX_TOKEN;

const EnergyMap: FC<EnergyMapProps> = ({}) => {
	if (!token) throw new Error('Mapbox token not found');

	const mapRef = useRef<MapRef>(null);
	const state = useMapState(mapRef);

	const [focused, setFocused] = useState<null | mapboxgl.MapboxGeoJSONFeature>(
		null
	);

	const { data, error, loading } = schema.description.get({
		variables: focused
			? {
					content: getFeaturePrompt(focused),
			  }
			: undefined,
		skip: !focused,
	});

	return (
		<Screen>
			<Map
				ref={mapRef}
				mapboxAccessToken={token}
				{...state.view.value}
				onMove={(e) => {
					state.view.setValue(e.viewState);
				}}
				onClick={(e) => {
					const feature = e.features?.[0];
					if (!feature) return;
					setFocused(feature);
				}}
				interactive
				interactiveLayerIds={['PlantExtrusions', 'plants-5v15sq', 'Plants']}
				mapStyle={state.connection}
				projection={{
					name: 'globe',
				}}
			/>
			<Legend state={state} />
			<DetailDrawer
				focused={focused}
				description={data?.Description.content || ''}
				loading={loading}
				onClose={() => setFocused(null)}
			/>
		</Screen>
	);
};

export default EnergyMap;
