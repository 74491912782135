import {
	Box,
	CircularProgress,
	FormControl,
	FormLabel,
	IconButton,
	Input,
	InputProps,
} from '@mui/joy';
import React, { FC } from 'react';
import { MdSearch, MdSend } from 'react-icons/md';

export interface SearchBoxProps extends Omit<InputProps, 'onChange' | 'value'> {
	loading: boolean;
	value: string;
	onChange: (value: string) => void;
	onSearch: (value: string) => void;
}

const SearchBox: FC<SearchBoxProps> = ({
	onSearch,
	loading,
	onChange,
	value,
	...rest
}) => {
	const handleSearch = (place: string) => {
		onSearch(place);
	};

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				handleSearch(value);
			}}
		>
			<FormControl>
				<FormLabel>Search</FormLabel>
				<Box sx={{ display: 'flex', gap: 1 }}>
					<Box sx={{ flex: 1 }}>
						<Input
							fullWidth
							startDecorator={
								loading ? (
									<CircularProgress
										sx={{
											'--CircularProgress-trackThickness': '4px',
											'--CircularProgress-progressThickness': '4px',
											'--CircularProgress-size': '14px',
										}}
									/>
								) : (
									<MdSearch />
								)
							}
							placeholder="Search"
							value={value}
							onChange={(e) => onChange(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleSearch(value);
								}
							}}
							{...rest}
						/>
					</Box>
					<Box>
						<IconButton
							onClick={() => handleSearch(value)}
							disabled={!value}
							variant="solid"
							color="primary"
							size={rest.size}
						>
							<MdSend />
						</IconButton>
					</Box>
				</Box>
			</FormControl>
		</form>
	);
};

export default SearchBox;
